import React from 'react';
import Content from './Content/Content';
import Chat from './Chat/Chat';
import { createWebSocket } from './Misc/Websocket';
import { loginCheck } from './Misc/Session';
import { ViewerList } from '../../types';
import { WebsocketListener } from './Misc/WebsocketListener';
import { ViewersContext } from './Contexts/ViewerContext';

createWebSocket();
export function Site(): JSX.Element {
  const [content, setContent] = React.useState<JSX.Element | null>(null);
  const [viewers, setViewers] = React.useState<ViewerList | null>(null);

  React.useEffect(() => {
    localStorage.setItem("blockedUsers", "");
    async function login() {
      const isAllowed = await loginCheck();
      if(isAllowed){
        setContent(
          <div data-testid='wrapper'>
            <div id='allowedRoot'>
              <WebsocketListener/>
              <Content/>
              <Chat />
            </div>
          </div>
        );
      } else {
        setContent(
          <div data-testid='wrapper'>
            <div id='forbiddenRoot'>
              <h1>You are not part of our server, please join</h1>
              <img src='/biribiri.png' alt='Biribiri' />
            </div>
          </div>
        );
      }
    }
    login();
  }, []);

  return (
    <ViewersContext.Provider value={{viewers, setViewers}}>
      {content}
    </ViewersContext.Provider>
  );
}