import './index.css';
import React from 'react';
import { Site } from './Site/Site';
import { createRoot } from 'react-dom/client';

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  const { worker } = await import('./Mocks/browser')
  return worker.start()
}

export function RootReactElement(): JSX.Element {
  return (
    <React.StrictMode>
      <Site />
    </React.StrictMode>
  );
}

await enableMocking();
const App = RootReactElement();
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(App);