import React, { useEffect, useState } from 'react';
import { websocket } from '../../Misc/Websocket';
import "./Viewer.css"
import { Viewer as ViewerInterface, MessageWritingViewerList, MessageBase } from '../../../../types';

export default function Viewer(props: ViewerInterface) {

  const [style, setStyle] = useState({});

  function writingListListener(event: MessageEvent) {
    const message: Partial<MessageBase> = JSON.parse(event.data);
    switch (message.command) {
    case "UPDATE_WRITING_LIST": {
      const writingViewerMessage: MessageWritingViewerList = JSON.parse(event.data);
      checkIfWriting(writingViewerMessage.writingViewers);
      break;
    }
    }
  }

  function messageListener() {
    websocket.addEventListener("message", writingListListener);
  }

  useEffect(() => {
    messageListener();
  }, []);

  function checkIfWriting(writingViewers: string[]) {
    if(writingViewers.includes(props.websocketId)) {
      setStyle(
        {
          animation: "shake 1s",
          animationIterationCount: "infinite",
        }
      );
    } else {
      setStyle(
        {
          animation: "none",
        }
      );
    }
  }

  //Block user
  function blockUser() {
    const blockedUsers = localStorage.getItem("blockedUsers");
    if(typeof blockedUsers === "string") {
      if(!blockedUsers.includes(props.userId)) {
        localStorage.setItem("blockedUsers", blockedUsers + "," + props.userId);
        setStyle(
          {
            "box-shadow": "0 0 10px red",
          }
        );
      } else {
        localStorage.setItem("blockedUsers", blockedUsers.replace("," + props.userId, ""));
        setStyle(
          {
            "box-shadow": "0 0 0px red",
          }
        );
      }
    }
  }

  return (
    <div className="viewer" onDoubleClick={blockUser} data-testid='viewer'>
      <span className="viewer-name">{props.username}</span>
      <img src={props.avatar.toString()} style={style} id={props.username} alt="viewer avatar" />
    </div>
  );
}