import "./ViewerBox.css";
import React from 'react';
import Viewer from '../Viewer/Viewer';
import { ViewersContext } from '../../Contexts/ViewerContext';

export default function ViewerBox() {
  const {viewers} = React.useContext(ViewersContext);
  const [ViewerList, setViewerList] = React.useState<JSX.Element[] | null>(null);
  React.useEffect(() => {
    if(viewers !== null && Object.keys(viewers).length > 0){
      setViewerList(Object.keys(viewers).map(viewer => <Viewer key={viewer} username={viewers[viewer].username} websocketId={viewer} avatar={viewers[viewer].avatar} userId={viewers[viewer].userId} />));
    } else {
      setViewerList([<div data-testid='no-viewers' style={{color: 'white'}} >No viewers</div>]);
    }
  }, [viewers]);

  return (
    <div className="viewer-box" data-testid='viewer-box'>
      {ViewerList}
    </div>
  );
}