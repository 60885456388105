const currentUrl = window.location.host;
export let websocket: WebSocket;

export function createWebSocket(): void {
  console.log("[Websocket] Creating websocket ...");
  if(process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
    console.log("Using development websocket: ws://localhost:8080");
    websocket = new WebSocket("ws://localhost:8080");
  } else {
    websocket = new WebSocket("wss://" + currentUrl + "/ws");
  }

  websocket.onopen = () => {
    console.log("[Websocket] Connection established");
  };

  websocket.addEventListener("close", () => {
    console.log("[Websocket] Lost connection to the server, reloading page in 15 sec ...");
    const event = new CustomEvent("reloadingPage");
    window.dispatchEvent(event);
    setTimeout(() => window.location.reload(), 15000);
  });

  websocket.onerror = (error) => {
    console.log(`[Websocket] error=${error.type}, error string=${JSON.stringify(error)}, websocket state=${websocket.readyState}`);
  }
}