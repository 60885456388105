import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import "./Box.css";
import Icon from '@mdi/react'
import { mdiSend, mdiLoading, mdiEmoticon } from '@mdi/js';
import { websocket } from '../../Misc/Websocket';
import EmojiPicker, { Categories, EmojiClickData, EmojiStyle, Theme } from 'emoji-picker-react';
import { Emoji, MessageTextWeb, MessageWriting } from '../../../../types';
let writing = false;

function getCookie(name: string): string | undefined {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}

export default function ChatBox(props: BoxProps) {

  const customEmojis = getAllEmojis();
  const [content, setContent] = useState<string>('');
  const [avatar, setAvatar] = useState<JSX.Element | null>(null);
  const [showEmojiPicker, setshowEmojiPicker] = useState<string>("none");
  const contentRef = useRef(content);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const currentTheme = Theme.DARK;
  const categories = [
    {category: Categories.SUGGESTED, name: Categories.SUGGESTED},
    {category: Categories.CUSTOM, name: "Discord"},
    {category: Categories.SMILEYS_PEOPLE, name: "Smileys & People"},
    {category: Categories.ANIMALS_NATURE, name: "Animals & Nature"},
    {category: Categories.FOOD_DRINK, name: "Food & Drink"},
    {category: Categories.TRAVEL_PLACES, name: "Travel & Places"},
    {category: Categories.ACTIVITIES, name: "Activities"},
    {category: Categories.OBJECTS, name: "Objects"},
    {category: Categories.SYMBOLS, name: "Symbols"},
    {category: Categories.FLAGS, name: "Flags"},
  ]

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user === null) {
      setAvatar(<Icon path={mdiLoading} className="loading-spinner" title="test" spin color="white" />);
      setTimeout(function () {
        if (user === null) {
          setAvatar(<img src={"https://avatarfiles.alphacoders.com/174/thumb-174822.png"} alt="user" />);
        } else {
          const avatarUrl = JSON.parse(user).avatar;
          setAvatar(<img src={avatarUrl} alt="user" />);
        }
      }, 5000);
    } else {
      const avatarUrl = JSON.parse(user).avatar;
      setAvatar(<img src={avatarUrl} alt="user" />);
    }
  }, []);

  function sendMessage() {
    if (!content.replace(/\s/g, '').length) {
      return;
    }
    const userId = JSON.parse(getUser()).id;
    const session = getCookie('session');
    if (session === undefined) {
      console.log('No session exists');
      props.systemMessage("💥No session existing, are you logged in correctly ?💥");
      return;
    }
    const messageObject: MessageTextWeb = { command: "SEND_MESSAGE_TO_BOT", userId: userId, session: session, content: content };
    const message = JSON.stringify(messageObject);
    websocket.send(message);
    setContent('');
    setshowEmojiPicker("none");
  }

  function handleChange(value: string) {
    setContent(value);
    if (!writing) {
      writing = true;
      const userId = JSON.parse(getUser()).id;
      const messageObject: MessageWriting = { command: "UPDATE_WRITING_LIST", userId: userId, writing: true };
      const message = JSON.stringify(messageObject);
      websocket.send(message);
    }
    stopWriting();
  }

  const stopWriting = useDebouncedCallback(() => {
    const userId = JSON.parse(getUser()).id;
    const messageObject: MessageWriting = { command: "UPDATE_WRITING_LIST", userId: userId, writing: false };
    const message = JSON.stringify(messageObject);
    websocket.send(message);
    writing = false;
  }, 6000);

  function onEnterPress(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      sendMessage();
    }
  }

  function isJson(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function getAllEmojis(): Emoji[] {
    const emojisString = localStorage.getItem('emojis');
    if (emojisString === null) {
      return [];
    }
    if(!isJson(emojisString)) {
      return [];
    }
    const emojiResponse = JSON.parse(emojisString);
    return emojiResponse.emojis;
  }

  function getUser(): string {
    const user = localStorage.getItem('user');
    if (user === null) {
      console.log('No user exists');
      return "";
    }
    return user;
  }

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  //Emojis
  useEffect(() => {
    async function fetchEmojis() {
      const emojiResponse = await fetch(window.location.href + 'emojis');
      if (emojiResponse.status === 200) {
        localStorage.setItem('emojis', await emojiResponse.text());
      }
    }
    fetchEmojis()
  }, []);

  function switchEmojiPicker() {
    if (showEmojiPicker === "none") {
      setshowEmojiPicker("block");
    } else {
      setshowEmojiPicker("none");
    }
  }

  function findEmoji(emojiData: EmojiClickData) {
    if(emojiData.isCustom === false) {
      setContent(contentRef.current + emojiData.emoji);
      return;
    }
    const emojiList = localStorage.getItem('emojis');
    if (emojiList === null) {
      return;
    }
    const emojis = JSON.parse(emojiList).emojis;
    const emoji = emojis.find((emoji: Emoji) => emoji.imgUrl === emojiData.imageUrl);
    setContent(contentRef.current + emoji.id);
  }

  useEffect(() => {
    contentRef.current = content;
  }, [content]);

  return (
    <div className="chat-input">
      {avatar}
      <textarea ref={inputRef} placeholder="Type a message..." value={content} onChange={evt => handleChange(evt.target.value)} onKeyDown={onEnterPress} />
      <div>
        <button type="button" onClick={switchEmojiPicker} id="emojipicker-btn">
          {<Icon path={mdiEmoticon}
            title="emojis"
            size={1}
            color="grey"
          />}
        </button>
      </div>
      <div className="chat-send">
        <button type="submit" onClick={sendMessage} id="send-msg-btn">
          {<Icon path={mdiSend}
            title="Send"
            size={1}
            color="white"
          />}
        </button>
      </div>
      <div className="emoji-picker" style={{ display: showEmojiPicker }}>
        <EmojiPicker
          width={"100%"}
          customEmojis={customEmojis}
          categories={categories}
          theme={currentTheme}
          previewConfig={{ showPreview: false }}
          onEmojiClick={emojiData => findEmoji(emojiData)}
          emojiStyle={EmojiStyle.NATIVE}
          lazyLoadEmojis={true}
        />
      </div>
    </div>
  );
}

type BoxProps = {
  systemMessage: (message: string) => void;
};