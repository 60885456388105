import React from "react";
import { websocket } from "./Websocket";
import { redirectToDiscordLogin } from "./Session";
import { ViewersContext } from "../Contexts/ViewerContext";
import { MessageBase, MessageViewerList, MessageViewer } from "../../../types";

export function WebsocketListener(): JSX.Element {
  const {setViewers} = React.useContext(ViewersContext);
  async function websocketListeners() {
    if(websocket.readyState !== websocket.OPEN) {
      await new Promise(resolve => websocket.addEventListener("open", resolve));
    }

    websocket.addEventListener("message", messageListener);

    function messageListener(event: MessageEvent) {
      const message: Partial<MessageBase> = JSON.parse(event.data);
      switch (message.command) {
      case "UPDATE_VIEWER_LIST": {
        const viewerMessage: MessageViewerList = JSON.parse(event.data);
        setViewers(viewerMessage.viewers);
        break;
      }}
    }
  }

  async function initialUpdate() {
    let user = localStorage.getItem('user');
    if (user === null) {
      const userResponse = await fetch(window.location.href + 'users');
      if (userResponse.status === 401 || userResponse.status === 404) {
        await redirectToDiscordLogin();
      }
      const userJson = await userResponse.json();
      localStorage.setItem('user', JSON.stringify(userJson.user));
      user = JSON.stringify(userJson.user);
    }
    const userJson = JSON.parse(user);
    const message: MessageViewer = {command: "UPDATE_VIEWER_LIST", userId: userJson.id};

    // Wait until the WebSocket is open
    try {
      await new Promise<void>((resolve, reject) => {
        if (websocket && websocket.readyState === websocket.OPEN) {
          resolve();
        } else if (websocket) {
          websocket.onopen = () => {
            resolve();
          };
          websocket.onerror = (error) => {
            reject(error);
          };
        } else {
          reject(new Error('WebSocket is not defined'));
        }
      });

      // The WebSocket is open, you can send messages now
      if (websocket) {
        websocket.send(JSON.stringify(message));
      }
    } catch (error) {
      // There was an error opening the WebSocket
      console.error('Error opening WebSocket', error);
    }
  }

  React.useEffect(() => {
    websocketListeners();
    initialUpdate();
  }, []);

  return (
    <></>
  );
}